<template>
  <div class="login">
    <div class="blur"></div>
    <div class="form_body">
      <h3 class="title">校园云采 - 饭堂管理后台</h3>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="login_form" @keyup.enter.native="submitForm('ruleForm')">
        <el-form-item prop="account">
          <el-input v-model="ruleForm.account" placeholder="请输入账号">
            <img slot="prefix" :src="require('@/assets/img/login/user.png')" />
          </el-input>
        </el-form-item>
        <el-form-item prop="password" class="pwd">
          <el-input v-model="ruleForm.password" show-password placeholder="请输入密码">
            <img slot="prefix" :src="require('@/assets/img/login/pass.png')" />
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="more">
            <!-- <div @click="goToUrl(1)">注册</div> -->
            <div style="margin-left: auto" @click="goToUrl(2)">忘记密码</div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="btnLoading" @click="submitForm('ruleForm')">登录</el-button>
        </el-form-item>
      </el-form>
    </div>

    <footers class="login_footers" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      btnLoading: false,
      ruleForm: {
        account: "",
        password: "",
      },
      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    }
  },
  created() {
    this.$store.dispatch("logout")
  },
  mounted() {
    localStorage.clear()
  },
  methods: {
    goToUrl(value) {
      if (value == 1) {
        this.$router.push({ path: "/register" })
      } else {
        this.$router.push({ path: "/forgotPassword" })
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.btnLoading = true
          this.$axios
            .post(this.$api.login, {
              userAccount: this.ruleForm.account,
              userPassword: this.ruleForm.password,
              sysCode: this.$sysCode,
              menuList: "0", // 0：PC端菜单,1：移动端菜单
            })
            .then(res => {
              // 清除登录信息显示
              if (sessionStorage.getItem("loginExpiredShown")) sessionStorage.removeItem("loginExpiredShown")
              this.$store.commit("setToken", res.data.result.token)
              this.$store.commit("setMenuList", res.data.result.sysMenuVoList)
              this.$store.commit("setUserInfo", res.data.result.loginUser)
              this.$store.commit("setIsLoggedIn", true)
              this.$message.success("登录成功")
              setTimeout(() => {
                if (res.data.result.sysMenuVoList.length > 0) {
                  this.$router.replace({ path: res.data.result.sysMenuVoList[0].menuPath })
                } else {
                  this.$router.replace("/404")
                }
              }, 500)
              this.btnLoading = false
            })
            .catch(() => {
              this.btnLoading = false
            })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .blur {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(../assets/img/login/login.png) no-repeat;
    background-size: cover;
    background-position: 100% 30%;
    filter: blur(4px);
  }

  .form_body {
    position: relative;
    padding: 22px 22px 0;
    border-radius: 6px;
    background-color: #fff;

    .title {
      color: #1a1a1a;
      font-size: 26px;
      text-align: center;
      font-weight: 500;
      margin-bottom: 60px;
    }

    .login_form {
      width: 400px;

      ::v-deep .el-input__inner {
        width: 100%;
        height: 50px;
        font-size: 16px;
        padding-left: 46px;
        border: 1px solid #dcdfe6;
        border-radius: 10px;
        background-color: #fff;

        &:hover {
          border-color: #c0c4cc;
        }

        &:focus {
          border-color: #c27c88;
        }
      }

      ::v-deep .el-input__prefix {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 46px;

        img {
          width: 16px;
          height: 18px;
        }
      }

      .pwd {
        margin-bottom: 12px;
      }

      .more {
        display: flex;
        color: #707070;

        div {
          cursor: pointer;
        }
      }

      .el-button {
        width: 100%;
        height: 50px;
        font-size: 16px;
        border-radius: 10px;
      }
    }
  }
}
</style>
